.app {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #E0F2F1;
    overflow-y: auto;

    header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: #00796B;
        margin: 52px 0 10px 0;

        h1 {
            margin: 0;
            font-weight: 500;
            font-size: 32px;
        }

        span {
            margin: 0 4px 0 1px;
            font-weight: 500;
            font-size: 22px;
        }

        h2 {
            margin: 0;
            font-weight: 500;
            font-size: 26px;
        }
    }

    .container {
        display: flex;
        flex-direction: row;
    }
}

@media screen and (max-width: 850px) {
    .app {
        .container {
            flex-direction: column;
            margin: 0 16px 16px 16px;
        }
    }
}


@media screen and (max-width: 450px) {
    .app {
        header {
            margin-top: 32px;
            h1 {
                font-size: 20px;
            }
            span {
                font-size: 18px;
            }
            h2 {
                font-size: 18px;
            }
        }
        .container {
            margin: 0 8px 8px 8px;
        }
    }
}