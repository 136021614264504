.month-grid {
    padding: 8px 10px 16px 10px;

    .day-headers {
        display: flex;
        flex-direction: row;
        width: 100%;

        .day-header {
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc(100% / 7);
            height: 50px;
            font-size: 20px;
            color: #0e0e0e;
        }



    }

    .days {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        .day {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: calc(100% / 7);
            padding: 8px 0;
            cursor: pointer;

            .nep {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 36px;
                height: 36px;
                font-size: 26px;
                color: #303030;
            }

            .eng {
                width: 100%;
                text-align: right;
                font-size: 14px;
                margin-right: 20%;
                color: #404040;
            }

            .tithi {
                font-size: 12px;
                color: #606060;
            }

            &.holiday {

                .nep {
                    color: rgb(214, 20, 20);
                }

                .eng {
                    color: rgb(214, 20, 20);
                }

                .tithi {
                    color: rgb(214, 20, 20, 0.8);
                }
            }

            &.today {
                .nep {
                    border: 1px solid #00796B;
                    border-radius: 50%;
                }
            }

            &:hover {
                background-color: #f0f0f0;
            }
        }
    }
}


@media screen and (max-width: 450px) {
    .month-grid {
        .day-headers {
            .day-header {
                font-size: 16px;
                height: 24px;
            }
        }

        .days {
            .day {
                padding: 4px 0;
                .nep {
                    font-size: 20px;
                }
                .eng {
                    font-size: 12px;
                }
                .tithi {
                    font-size: 11px;
                }
            }
        }
    }
}