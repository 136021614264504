.month-view {
    width: 60vw;
    max-width: 785px;
    min-width: 455px;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 1px 1px 10px -3px rgba(0,0,0,0.5);
    overflow: hidden;
    border-radius: 8px;
    align-self: flex-start;
}


@media screen and (max-width: 850px) {
    .month-view {
        width: auto;
        max-width: initial;
        min-width: initial;
    }
}