.side-bar {
    display: flex;
    flex-direction: column;
    width: 330px;
    // justify-content: space-between;

    .today {
        display: flex;
        flex-direction: column;
        // height: 105px;
        background-color: #00796B;
        color: white;

        margin-left: 16px;
        margin-bottom: 16px;
        box-shadow: 1px 1px 10px -3px rgba(0,0,0,0.5);
        overflow: hidden;
        border-radius: 8px;
        cursor: pointer;

        h4 {
            margin: 0;
            padding: 10px 0 8px 10px;
            line-height: 1;
            font-size: 18px;
            font-weight: 600;
            border-bottom: 1px solid rgba(255, 255, 255, 0.6);
            margin-bottom: 6px;
        }

        .date {
            padding: 0 10px;
            font-size: 20px;
        }

        .tithi {
            padding: 4px 10px;
            font-size: 14px;
        }

        .event {
            padding: 0 10px;
            font-size: 14px;
            margin-bottom: 8px;
            opacity: 0.8;
        }
    }

    .event-list {
        display: flex;
        flex-direction: column;

        background-color: white;
        margin-left: 16px;
        box-shadow: 1px 1px 10px -3px rgba(0,0,0,0.5);
        overflow: hidden;
        border-radius: 8px;
        padding-bottom: 6px;

        h4 {
            margin: 0;
            padding: 10px 0 8px 10px;
            line-height: 1;
            font-size: 18px;
            font-weight: 600;
            border-bottom: 1px solid #00796B;
            margin-bottom: 6px;
            color: #00695C;
        }

        .event-row {
            display: flex;

            .date {
                padding: 2px 6px;
                font-size: 16px;
                opacity: 0.85;
                width: 24px;
            }

            .event {
                padding: 2px 6px 2px 0;
                opacity: 0.85;
                width: calc(100% - 36px);
            }

            &.holiday {
                color: rgb(214, 20, 20);
            }
        }
    }
}


@media screen and (max-width: 850px) {
    .side-bar {
        margin-top: 16px;
        width: auto;

        .today {
            margin-left: 0;
        }

        .event-list {
            margin-left: 0;
        }
    }
}

@media screen and (max-width: 450px) {
    .side-bar {
        margin-top: 8px;
        .today {
            margin-bottom: 8px;
            h4 {
                font-size: 16px;
            }
            .date {
                font-size: 18px;
            }
            .tithi {
                font-size: 13px;
            }
            .event {
                font-size: 13px;
            }
        }
        .event-list {
            h4 {
                font-size: 16px;
            }
            .event-row {
                .date {
                    font-size: 14px;
                }
                .event {
                    font-size: 14px;
                }
            }
        }
    }
}